import ScreenHeader from '@/components/ScreenHeader/ScreenHeader.vue';
import DocumentService from '../../../services/API/document.service';
import TransferService from '@/services/API/transfer.service';
import { mapState } from 'vuex';
import Axios from 'axios';
import socketClient from '@/services/SOCKET';

const EXPIRATION = 31536000;

export default {
	name: 'DocumentDownload',
	components: { ScreenHeader },
	computed: {
		// Get projectId, userId from store
		...mapState(['projectId', 'userId', 'projectInfo', 'clientInfo']),
	},
	data() {
		return {
			screenIcon: 'download-icon',
			screenName: '書類ダウンロード',
			middleContent: ' 様 ／ ',
			filteredDocuments: [],
			documents: [
				{
					name: '見積書',
					icon: 'quotation.svg',
					key: 'quotation',
					show: false,
					url: '',
				},
				{
					name: '納品書',
					icon: 'delivery_note.svg',
					key: 'delivery_split',
					show: false,
					url: '',
				},
				{
					name: '請求書',
					icon: 'invoice.svg',
					key: 'invoice',
					show: false,
					url: '',
				},
				{
					name: '領収書',
					icon: 'receipt.svg',
					key: 'receipt',
					show: false,
					url: '',
				},
			],
			documentsDownload: {},
		};
	},
	methods: {
		async getDocuments() {
			// Get documents by projectId
			await DocumentService.get(this.projectId).then((response) => {
				console.log({ response });
				this.documentsDownload = response.data;
			});
		},
		async filerDocuments() {
			// Filter documents by projectId
			for (let document of this.documents) {
				if (this.documentsDownload[document.key]) {
					document.show = true;
					document.url = await this.getFileUrl(
						this.documentsDownload[document.key],
						EXPIRATION
					);
					if (!document.url) {
						document.show = false;
					}
				}
			}
			let orderDocSet = [];

			let filterOrderConfirmList = [];
			let orderConfirmList = [];
			if (this.documentsDownload['order_confirm']) {
				orderConfirmList = [...this.documentsDownload['order_confirm']];
				orderConfirmList.forEach(async (document, index) => {
					filterOrderConfirmList.push({ name: '', url: '', icon: 'order_confirm.svg' });
					filterOrderConfirmList[index]['name'] =
						index != 0
							? '発注請書_' + document.split('-').at(-1).split('.')[0]
							: '発注請書';
					filterOrderConfirmList[index]['url'] = await this.getFileUrl(
						document,
						EXPIRATION
					);
				});
			}

			let filterPurchaseOrder = [];
			let purchaseOrder = [];

			if (this.documentsDownload['purchase_order']) {
				purchaseOrder = [...this.documentsDownload['purchase_order']];
				purchaseOrder.forEach(async (document, index) => {
					filterPurchaseOrder.push({ name: '', url: '', icon: 'purchase_order.svg' });
					filterPurchaseOrder[index]['name'] =
						index != 0
							? '発注書_' + document.split('-').at(-1).split('.')[0]
							: '発注書';
					filterPurchaseOrder[index]['url'] = await this.getFileUrl(
						document,
						EXPIRATION
					);
				});
			}

			for (let i = 0; i < orderConfirmList.length; i++) {
				orderDocSet.push(filterPurchaseOrder[i]);
				orderDocSet.push(filterOrderConfirmList[i]);
			}
			this.filteredDocuments = [...orderDocSet];
		},
		async getFileUrl(key, expiration) {
			try {
				let response = await TransferService.get(key, expiration);
				if (!response || response.status !== 200) {
					throw 'Get image url failed!';
				}

				console.log('%c Get image url successfully!', 'color: red');
				return response.data.link;
			} catch (error) {
				console.log(error);
			}
		},
		async downloadDocument(url, name) {
			this.$store.commit('setIsAppProcessing', true);

			console.log(url);
			// Using Axios get PDF file to browser and rename it before downloading
			// Reason: Can not rename PDF file directly by external URL just using <a> tag
			let pdfFile = await Axios.get(url, {
				headers: {
					'Content-Type': 'application/octet-stream',
				},
				responseType: 'blob',
			});
			let fileName = this.projectInfo['project_name'] + '_' + name + '.pdf';
			let listATag = this.$refs.downloadTag;
			let aTag = listATag;
			let objectUrl = window.URL.createObjectURL(pdfFile.data);
			aTag.href = objectUrl;
			aTag.download = fileName;
			console.log(aTag);
			aTag.click();

			this.$store.commit('setIsAppProcessing', false);
		},
	},
	async mounted() {
		this.$store.commit('setIsAppProcessing', true);

		await this.getDocuments();
		await this.filerDocuments();
		console.log('DocumentDownload mounted', this.documentsDownload, this.documents);
		console.log(this.documents);

		this.$store.commit('setIsAppProcessing', false);

		socketClient.listen('new_receipt_created', async () => {
			await this.getDocuments();
			await this.filerDocuments();
		});
	},

	beforeDestroy() {
		socketClient.removeAllListeners('new_receipt_created');
	},
};
